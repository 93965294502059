import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory
import './Home.css';

function Home({ isLoggedIn }) {
  const navigate = useNavigate(); // Use useNavigate for navigation

  const handleBookRide = () => {
    if (isLoggedIn) {
      // If the user is logged in, proceed to the booking page
      navigate('/bookings');
    } else {
      // If the user is not logged in, redirect to the login page
      navigate('/login');
    }
  };

  return (
    <div className="home-container">
      <video autoPlay loop muted className="home-video">
        <source src="/car1.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="home-content">
        <div className="content-card">
          <h1>Welcome to TOCXI</h1>
          <p>Instant rides, anywhere, anytime—experience the convenience of TOCXI.</p>
          <button className="cta-button" onClick={handleBookRide}>
            Book Your Ride
          </button>
        </div>
      </div>

      <section className="features-section">
        <div className="features-grid">
          <div className="feature-item">
            <i className="fas fa-clock"></i>
            <h3>On-Time Rides</h3>
            <p>Arrive on time, every time with TOCXI.</p>
          </div>
          <div className="feature-item">
            <i className="fas fa-map-marker-alt"></i>
            <h3>Real-Time Tracking</h3>
            <p>Track your ride from anywhere with ease.</p>
          </div>
          <div className="feature-item">
            <i className="fas fa-money-bill-alt"></i>
            <h3>Cash Payments</h3>
            <p>Pay in cash directly to the driver for a hassle-free ride.</p>
          </div>
        </div>
      </section>

      <footer className="footer-section">
        <div className="footer-content">
          <p>© 2024 TOCXI. All rights reserved.</p>
          <ul className="footer-links">
            <li><a href="#privacy">Privacy Policy</a></li>
            <li><a href="#terms">Terms & Conditions</a></li>
            <li><a href="#contact">Contact Us</a></li>
          </ul>
        </div>
      </footer>
    </div>
  );
}

export default Home;
